<template>
  <div class="pageAligment">
    <validation-observer ref="updateSettings">
      <b-form>
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="نسبة نقطة البيع" label-for="selling-point-rate">
                  <validation-provider
                  v-slot="{ errors }"
                  name="نسبة نقطة البيع"
                  rules="required"
                >
              <b-input-group>
                <template #append>
                  <b-input-group-text>
                    <strong>%</strong>
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="selling-point-rate"
                  v-model="settingsForm.posRate"
                  type="number"
                />
              </b-input-group>
               <small class="text-danger">{{ errors[0] }}</small>
             </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              label="قيمة الجواب لمجيبو الأسئلة"
              label-for="question-responser-rate"
            >
             <validation-provider
                  v-slot="{ errors }"
                  name="قيمة الجواب لمجيبو الأسئلة"
                  rules="required"
                >
              <b-input-group>
                <template #append>
                  <b-input-group-text> ل.س </b-input-group-text>
                </template>
                <b-form-input
                  id="question-responser-rate"
                  v-model="settingsForm.responderAnsValue"
                  type="number"
                />
              </b-input-group>
               <small class="text-danger">{{ errors[0] }}</small>
             </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="نسبة الأساتذة" label-for="master-rate">
                  <validation-provider
                  v-slot="{ errors }"
                  name="نسبة الأساتذة"
                  rules="required"
                >
              <b-input-group>
                <template #append>
                  <b-input-group-text>
                    <strong>%</strong>
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="teacher-rate"
                  v-model="settingsForm.teacherRate"
                  type="number"
                />
              </b-input-group>
               <small class="text-danger">{{ errors[0] }}</small>
             </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group class="mt-2 text-center">
          <b-button
            size="md"
            type="submit"
            variant="primary"
            class="btn btn-primary"
            @click.prevent="SubmitUpdateRates()"
          >
            تعديل
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </div>
</template>
<style scoped>
.pageAligment {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 360px;
}
</style>
<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupText,
  BCol,
  BRow,
  BButton,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";
export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupText,
    BCol,
    BRow,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  created() {
    localize("ar");
    this.fetchSettings();
  },
  data() {
    return {
      required,
    };
  },
  computed: {
    ...mapGetters(["settingsForm"]),
  },
  methods: {
    ...mapActions(["fetchSettings", "setSettings","setPic"]),
    SubmitUpdateRates() {
      this.$refs.updateSettings.validate().then((success) => {
        if (success) {
          this.setSettings(this.settingsForm);
          this.$bvToast.toast(`تم تعديل النسب بنجاح`, {
            title: "تم التعديل",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        }
      });
    },
  },
};
</script>
